<template>
  <div>
    <v-app-bar class="mb-6" flat color="background">
      <v-app-bar-nav-icon @click="switchDrawer()"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-img
          src="../assets/img/logo-falabellacorp.png"
          height="30"
          width="140"
          contain
        ></v-img>
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "appbar",

  methods: {
    switchDrawer() {
      this.$emit("switchDrawer");
    },
  },
};
</script>

<style></style>
